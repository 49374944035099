import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbNavModule, NgbDropdownModule, NgbTooltipModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

// ANCHOR NGPRIME
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { DateToMomentPipe } from '../../pipes/date-to-moment.pipe';



@NgModule({
  declarations: [
    DateToMomentPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TableModule,
    InputSwitchModule,
    ButtonModule,
    DropdownModule,
    NgSelectModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbDatepickerModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TableModule,
    InputSwitchModule,
    ButtonModule,
    DropdownModule,
    NgSelectModule,
    NgbNavModule,
    NgbDropdownModule,
    DateToMomentPipe,
    NgbTooltipModule,
    NgbDatepickerModule,
  ]
})
export class SharedModule { }
